import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'
import { SectionWrapper } from './style'

const ComoVaiFuncionar = () => {
  return (
    <SectionWrapper>
      <div className='container py-5'>
        <div className='row justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center flex-column mb-4'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-white'>Acompanhe as <span className='d-lg-block'>nossas novidades</span></h2>
            <p className='fs-18 lh-22 text-white'>Fique por dentro de tudo que acontece no dia a dia do Inter.</p>
          </div>
          <div className='col-12 col-md-6 offset-lg-1'>
            <div className='social-media-card rounded-5 mb-4'>
              <div className='d-flex'>
                <Icon color='orange--base' width='24' height='24' icon='social/new-instagram' directory='v2' />
                <p className='fs-16 lh-20 fw-600 text-grayscale--500 ml-3'>Instagram</p>
              </div>
              <div className='d-lg-flex d-lg-flex flex-wrap justify-content-between'>
                <p className='fs-16 lh-20 text-grayscale--500 p-250'>Conteúdos que simplificam sua experiência com o Inter</p>
                <a className='fs-14 lh-16 text-orange--extra fw-400' href='https://instagram.com/interbr' target='_blank' rel='noreferrer'>Siga nosso Instagram
                  <Icon color='orange--base' width='24' height='24' icon='navigation/arrow-right' directory='v2' className='ml-2' />
                </a>
              </div>
            </div>
            <div className='social-media-card rounded-5'>
              <div className='d-flex'>
                <Icon color='orange--base' width='24' height='24' icon='social/new-youtube' directory='v2' />
                <p className='fs-16 lh-20 fw-600 text-grayscale--500 ml-3'>YouTube</p>
              </div>
              <div className='d-lg-flex d-lg-flex flex-wrap justify-content-between'>
                <p className='fs-16 lh-20 text-grayscale--500 p-200'>Vídeos e lives que simplificam o seu dia a dia e sua vida financeira</p>
                <a className='fs-14 lh-16 text-orange--extra fw-400' href='https://open.spotify.com/show/2RGqqOTrQqTCplWIk5ht0j' target='_blank' rel='noreferrer'>Inscreva-se no nosso canal
                  <Icon color='orange--base' width='24' height='24' icon='navigation/arrow-right' directory='v2' className='ml-2' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ComoVaiFuncionar
