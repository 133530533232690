import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import FasesJSON from './data.json'
import { SectionWrapper, Date, WeAreHere } from './style'

type FasesCarouselProps = {
  date: string;
  title: string;
  weAreHere?: boolean;
  description: string;
}

const ComoVaiFuncionar = () => {
  const fases = FasesJSON

  return (
    <SectionWrapper>
      <div className='container py-5'>
        <div className='row justify-content-lg-center'>
          <div className='col-12 text-center'>
            <h2 className='fs-24 lh-30 fs-xl-40 lh-xl-50 text-white fw-600 mb-3'>Como vai funcionar</h2>
            <p className='text-white fs-18 lh-22'>O Open Finance já começou e está dividido em 4 fases: </p>
          </div>
          <div className='col-12 col-lg-9 mt-2'>
            <DefaultCarousel
              sm={{ items: 1, slidesToSlide: 1 }}
              md={{ items: 2, slidesToSlide: 2 }}
              lg={{ items: 2, slidesToSlide: 2 }}
              xl={{ items: 2, slidesToSlide: 2 }}
            >
              {
              fases.map((item: FasesCarouselProps, index: number) => (
                <div key={index} className='carousel-card bg-white rounded-3 py-3 px-3 mx-md-2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <Date className='fs-16 lh-20 fs-md-18 text-orange--extra fw-700 mb-3 d-inline' dangerouslySetInnerHTML={{ __html: item.date }} />
                    {item.weAreHere && <WeAreHere className='fs-10 lh-12 fw-700 text-white mb-3'>Estamos aqui</WeAreHere> }
                  </div>
                  <h2 className='fs-18 lh-21 fs-xl-24 lh-xl-30 text-grayscale--500 fw-700 mb-3 mt-md-3' dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p className='fs-12 lh-14 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              ))
            }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ComoVaiFuncionar
