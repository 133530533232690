import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import OpenBankConta from './../../assets/open-banking-sua-conta-segura.png'
import OpenBankContaLG from './../../assets/open-banking-sua-conta-segura-lg.png'
import OpenBankContaMD from './../../assets/open-banking-sua-conta-segura-md.png'
import OpenBankContaMobile from './../../assets/open-banking-sua-conta-segura-mobile.png'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background-color: ${grayscale[100]};
  background-image: url(${OpenBankContaMobile});
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 880px;
  display: flex;
  align-items: flex-end;
  background-position: top;

  @media (min-width: 450px) {
    min-height: 930px;
  }

  @media ${device.mobile} {
    background-image: url(${OpenBankContaMD});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right -135px top 0;
    min-height: 500px;
    align-items: center;
  }

  @media ${device.tablet} {
    background-image: url(${OpenBankContaMD});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right -50px top 0;
  }

  @media ${device.desktopLG} {
    background-image: url(${OpenBankContaLG});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right -150px top 0;
  }

  @media ${device.desktopXL} {
    background-image: url(${OpenBankConta});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
`
