import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

import { SectionWrapper } from './style'

const QuaisVantagens = () => {
  const data = usePageQuery()

  return (
    <SectionWrapper>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 order-2 order-md-1'>
            <h2 className='fs-24 lh-30 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'>
              Quais as vantagens do <span className='d-lg-block d-xl-inline'>Open Finance?</span>
            </h2>
            <div className='my-3 d-flex align-items-center pt-xl-5'>
              <Icon color='orange--base' width='24' height='24' icon='interui/portability' directory='v2' />
              <p className='fs-16 lh-22 text-grayscale--400 ml-3 mb-0'>
                Menos burocracia para <span className='d-block d-md-inline'>trocar de banco</span>
              </p>
            </div>
            <div className='my-3 d-flex align-items-center'>
              <div>
                <Icon color='orange--base' width='24' height='24' icon='navigation/search' directory='v2' />
              </div>
              <p className='fs-16 lh-22 text-grayscale--400 ml-3 mb-0'>
                Consulta de saldos e movimentações mais rápidas
              </p>
            </div>
            <div className='my-3 d-flex align-items-center'>
              <div>
                <Icon color='orange--base' width='24' height='24' icon='products/banco/cashback' directory='v2' />
              </div>
              <p className='fs-16 lh-22 text-grayscale--400 ml-3 mb-0'>
                Mais agilidade nas transferências entre suas contas
              </p>
            </div>
            <div className='my-3 d-flex align-items-center'>
              <Img fluid={data.openBankingInterUIServices.fluid} alt='Ícone Inter Services' />
              <p className='fs-16 lh-22 text-grayscale--400 ml-3 mb-0'>
                Mercado financeiro mais competitivo e com mais opções
              </p>
            </div>
            <div className='mt-3 d-flex align-items-center'>
              <Icon color='orange--base' width='24' height='24' icon='interui/light-on' directory='v2' />
              <p className='fs-16 lh-22 text-grayscale--400 ml-3 mb-0'>
                Mais facilidade para fazer suas escolhas
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 order-1 order-md-2 mb-4 mb-md-0'>
            <Img fluid={data.openBankingVantagens.fluid} alt='Mulher com uma jaqueta laranja utilizando o app do Inter em seu celular.' />
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default QuaisVantagens
