import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  min-height: 520px;
  display: flex;
  position: relative;

  @media ${device.tablet} {
    min-height: 735px;
  }
  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }
  
  .bread-crumb {
    margin-bottom: 15px;
    span, a {
      color: #161616!important;
      font-size: 14px;
      line-height: 16px;
    }
  }
`

export const ScrollLink = styled.div`
  position: absolute;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: 240px;
  bottom: 20px;
`
