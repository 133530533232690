import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import OpenBankCafe from './../../assets/open-banking-cafe.png'
import OpenBankCafeMobile from './../../assets/open-banking-cafe-mobile.png'
import OpenBankCafeMD from './../../assets/open-banking-cafe-md.png'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background-color: ${grayscale[100]};
  background-image: url(${OpenBankCafeMobile});
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 870px;
  display: flex;
  align-items: flex-end;

  @media(min-width: 450px) {
    min-height: 930px;
  }

  @media ${device.mobile} {
    background-image: url(${OpenBankCafeMD});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: -170px 0; 
    min-height: 500px;
  }

  @media ${device.tablet} {
    background-image: url(${OpenBankCafe});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: -200px 0; 
    min-height: 500px;
    align-items: center;
  }

  @media ${device.desktopLG} {
    background-image: url(${OpenBankCafe});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
    background-position: -100px 0; 
  }


  @media ${device.desktopXL} {
    background-image: url(${OpenBankCafe});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
    background-position: 0 0; 
  }
  
`
