import React from 'react'

import { SectionWrapper } from './style'

const SuaContaSegura = () => {
  return (
    <SectionWrapper id='mais-autonomia'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-center justify-content-sm-start'>
          <div className='col-12 col-md-6 col-lg-5 pr-0'>
            <h2 className='fs-24 lh-30 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-3 pr-0'>
              Sua conta sempre segura <span className='d-lg-block d-xl-inline'>no Inter</span>
            </h2>
            <p className='fs-18 lh-22 text-grayscale--400'>
              No Open Finance todo o compartilhamento de dados e integração de informações entre as instituições autorizadas são realizadas somente após seu consentimento.
            </p>
            <p className='fs-18 lh-22 text-grayscale--400 mb-0'>
              O Inter não compartilha seus dados ou suas informações de conta sem sua autorização. Continuamos simplificando sua vida, com a mesma segurança de sempre.
            </p>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default SuaContaSegura
