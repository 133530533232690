import styled from 'styled-components'
import { green, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  background-color: ${orange.extra};
  display: flex;
  align-items: center;

  .carousel-card {
    min-height: 245px;
    @media ${device.desktopXL} {
      min-height: 334px;
    }
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color: #fff;
      }
    }
  }

  .react-multiple-carousel__arrow--right {
    svg{
      fill: #fff;
    }
  }

  .react-multiple-carousel__arrow--left {
    svg{
      fill: #fff;
    }
  }
`

export const Date = styled.p`
  @media ${device.tablet} {
    background-color: ${orange[100]};
    padding: 2px 24px;
    border-radius: 16px;
  }
`

export const WeAreHere = styled.span`
  padding: 2px 4px;
  background-color: ${green[400]};
  border-radius: 4px;
`
