import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Benefits = styled.div`
  border-bottom: 1px solid ${grayscale[200]};

  @media ${device.tablet} {
    border-bottom: none;
    border-radius: 8px;
    border: 1px solid ${grayscale[100]};
    height: 200px;
  }

`
