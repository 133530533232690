import { orange } from 'src/styles/colors'
import styled from 'styled-components'
import { breakpoints } from '../../../../../styles/breakpoints'

export const StepperContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid ${orange.extra};
    width: 100%;
    top: 26px;
    left: -50%;
    z-index: 2;
  }

  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid ${orange.extra};
    width: 100%;
    top: 26px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 54px;
    min-height: 54px;
    border-radius: 12px;
    background: ${orange.extra};
  }

  .step-counter {
    color: white;
    font-size: 24px;
  }

  .step-name {
    margin-top: -24px;
    background: #F5F6FA;
    border-radius: 12px;
    min-height: 287px;
    padding: 32px 21px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: -30px 4px 0 4px;

    @media (min-width: ${breakpoints.md}) {
      text-align: center;
    }
  }

  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
`
