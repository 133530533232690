import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'
import pageContext from './pageContext.json'

import ContentHeader from './sections/ContentHeader/_ContentHeader'
import MaisAutonomia from './sections/MaisAutonomia/_MaisAutonomia'
import QuaisVantagens from './sections/QuaisVantagens/_QuaisVantagens'
import ComoVaiFuncionar from './sections/ComoVaiFuncionar/_ComoVaiFuncionar'
import TrazerDinheiro from './sections/TrazerDinheiro/_TrazerDinheiro'
import SuaContaSegura from './sections/SuaContaSegura/_SuaContaSegura'
import FAQ from './sections/FAQ/_FAQ'
import FiquePorDentro from './sections/FiquePorDentro/_FiquePorDentro'
import Novidades from './sections/Novidades/_Novidades'

const OpenBanking = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <ContentHeader />
        <MaisAutonomia />
        <QuaisVantagens />
        <ComoVaiFuncionar />
        <TrazerDinheiro />
        <SuaContaSegura />
        <FAQ faq={pageContext.structuredData.faq} />
        <FiquePorDentro />
        <Novidades />
      </Layout>
    </Wrapper>
  )
}

export default OpenBanking
