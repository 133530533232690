import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import ScrollTo from 'src/components/ScrollTo'
import Icon from 'src/components/UI/MarkdownIcon'
import BreadCrumb from 'src/components/Breadcrumb'
import { SectionWrapper, ScrollLink } from './style'

import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const WIDTH_MD = 768

const ContentHeader = () => {
  const data = usePageQuery()
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionWrapper>
      <div className='container d-md-flex align-items-md-center pb-5'>
        <div className='row align-items-center justify-content-center justify-content-md-between'>
          <div className='col-12 col-md-6 col-lg-5 order-2 order-md-1 px-0 px-md-3'>
            <div className='d-none d-md-block'>
              <BreadCrumb
                sectionName='Open Finance: O Inter pronto para o futuro'
                type='O Inter'
                text='Open Finance'
                link='/open-finance/'
              />
            </div>
            <h1 className='fs-32 lh-40 fs-md-40 lh-md-50 fs-xl-48 lh-xl-60 fw-600 text-grayscale--500 mb-3'>
              Open Finance: O Inter pronto <span className='d-lg-block d-xl-inline'>para o futuro</span>
            </h1>
            <p className='fs-18 lh-22 text-grayscale--400'>
              Open Finance é o compartilhamento padronizado de dados e serviços bancários entre as instituições autorizadas pelo Banco Central.
            </p>
            <p className='fs-18 lh-22 text-grayscale--400'>
              Com a integração de dados, produtos e serviços bancários e financeiros, você terá em suas mãos o poder de decidir o que faz mais sentido pra você.
            </p>
            <p className='fs-18 lh-22 text-grayscale--400 mb-0'>
              Será possível movimentar suas contas e seus gastos, inclusive de outros bancos, no mesmo lugar.
            </p>
          </div>
          {windowWidth >= WIDTH_MD &&
            <div className='col-12 col-md-6 col-lg-5 offset-lg-1 order-1 order-md-2 my-4 my-md-0'>
              <Img fluid={data.openBankingHeader.fluid} alt='Homem no topo de uma montanha estendendo os braços' className='float-lg-right' />
            </div>
          }
        </div>
      </div>
      <ScrollTo
        to='#mais-autonomia'
        styles='text-center'
        section='dobra_01'
        sectionName='Open Finance: O Inter pronto para o futuro'
        elementName='Saiba mais sobre o Open Finance'
      >
        <ScrollLink>
          <p className='fs-14 fw-700 text-orange--base mb-0'>Saiba mais sobre o Open Finance</p>
          <Icon color='orange--base' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
        </ScrollLink>
      </ScrollTo>
    </SectionWrapper>
  )
}

export default ContentHeader
