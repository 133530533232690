import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Stepper from './Stepper/_index'
import StepperHorizontal from './StepperHorizontal/_index'

import { Benefits } from './style'

// hooks
import useWidth from '../../../../hooks/window/useWidth'

const WIDTH_MD = 768

const TrazerDinheiro = () => {
  const dataJson = [
    '<span class="d-block mb-3 fw-md-600 mt-md-2 text-md-center w-100">Open Finance</span> <span class="fs-md-16 lh-md-19">Na home do Super App, clique na aba “Open Finance”</span>',
    '<span class="d-block mb-3 fw-md-600 mt-md-2 text-md-center w-100">Trazer Dinheiro</span> <span class="fs-md-16 lh-md-19">Em seguida, selecione a opção “Trazer Dinheiro”</span>',
    '<span class="d-block mb-3 fw-md-600 mt-md-2 text-md-center w-100">Escolha a instituição</span> <span class="fs-md-16 lh-md-19">Você seleciona a instituição na qual irá debitar o valor selecionado</span>',
    '<span class="d-block mb-3 fw-md-600 mt-md-2 text-md-center w-100">Pronto</span> <span class="fs-md-16 lh-md-19">Aprove o pagamento na outra instituição, e ao voltar para o app do Inter, é só conferir o status da sua solicitação</span>',
  ]
  const windowWidth = useWidth(300)
  const isMobileSize = windowWidth < WIDTH_MD

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 mb-md-3'>
            <h2 className='fs-24 fs-md-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600 mb-4 text-md-center'>
              <span className='d-block d-md-inline'>Trazer Dinheiro:</span> movimente suas contas com mais praticidade!
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-20 text-grayscale--400 text-md-center fw-400'>No Inter, você tem uma nova forma de fazer uma movimentação entre contas: o <span className='fw-600'>Trazer Dinheiro</span>. Ele vem justamente com o intuito de facilitar a vida de quem tem conta em mais de uma instituição financeira e deseja transferir alguma quantia de uma para a outra. Tudo isso de forma segura, simples e, é claro, digital.</p>
          </div>
          <div className='col-12 col-md-4 mt-2'>
            <Benefits className='px-md-3 py-md-3'>
              <div className='d-flex d-md-block pt-2 pt-md-0'>
                <div className='mr-2'>
                  <OrangeIcon size='MD' color='#ff7a00' icon='check-circle' />
                </div>
                <div>
                  <p className='fs-16 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 fw-600 mt-1 mt-md-3'>Praticidade</p>
                </div>
              </div>
              <p className='fs-14 fs-lg-16 lh-16 lh-lg-20 text-grayscale--400'>Não é necessário transitar entre as contas nem utilizar chave Pix</p>
            </Benefits>
          </div>
          <div className='col-12 col-md-4 mt-2'>
            <Benefits className='px-md-3 py-md-3'>
              <div className='d-flex d-md-block pt-2 pt-md-0'>
                <div className='mr-2'>
                  <OrangeIcon size='MD' color='#ff7a00' icon='security' />
                </div>
                <div>
                  <p className='fs-16 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 fw-600 mt-1 mt-md-3'>Segurança</p>
                </div>
              </div>
              <p className='fs-14 fs-lg-16 lh-16 lh-lg-20 text-grayscale--400'>Faça tudo dentro do Super App e tenha total controle de todas as suas transações financeiras</p>
            </Benefits>
          </div>
          <div className='col-12 col-md-4 mt-2'>
            <Benefits className='px-md-3 py-md-3'>
              <div className='d-flex d-md-block pt-2 pt-md-0'>
                <div className='mr-2'>
                  <OrangeIcon size='MD' color='#ff7a00' icon='spending' />
                </div>
                <div>
                  <p className='fs-16 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 fw-600 mt-1 mt-md-3'>Facilidade</p>
                </div>
              </div>
              <p className='fs-14 fs-lg-16 lh-16 lh-lg-20 text-grayscale--400'>Para completar o valor da compra, ou para deixar seu dinheiro em só um lugar</p>
            </Benefits>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-32 fs-xl-40 lh-xl-50 lh-30 lh-md-40 text-grayscale--500 fw-600 text-md-center'>Como utilizar  a função Trazer Dinheiro?</h2>
            <p className='fs-14 fs-md-18 lh-16 lh-md-22 text-grayscale--400 text-grayscale--400 fw-400 mt-3 text-md-center mb-5'>É simples! Você só precisa seguir 4 passos a seguir:</p>
            {isMobileSize ? <Stepper data={dataJson} /> : <StepperHorizontal data={dataJson} />}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TrazerDinheiro
