import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const BlogCarousel = styled.div`
  .gatsby-image-wrapper {
    max-width: 100%!important;
  }

  .card {
    border-radius: 16px 16px 0 0;
  }

  .primary-tag, .secundary-tag {
    display: inline;
  }

  &.p-card {
    padding: 0;
  
    @media ${device.tablet} { 
      padding-right: 24px;
    }
    @media ${device.desktopLG} { 
      padding-right: 10.5px;
      padding-left: 10.5px
    }     
  }

  article {
    display: grid;
    min-height: 350px;
    @media ${device.desktopLG} { 
      min-height: 470px;
    }
  }

  .react-multi-carousel-track {
    padding: 30px 0 10px!important;
  }

  .title-post {
    font-family: 'Sora';
  }
`
