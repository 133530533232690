import React from 'react'
import { StepperContainer } from './style'
interface IStepperProps {
  data: string[];
}

const StepperHorizontal = ({ data }: IStepperProps) => {
  return (
    <StepperContainer>
      {data?.map((d: string, index: number) => (
        <div key={d} className='stepper-item'>
          <div className='step-counter'>{index + 1}</div>
          <div className='step-name fs-lg-20 lh-lg-22 text-grayscale--500 flex-column' dangerouslySetInnerHTML={{ __html: d }} />
        </div>
      ))}
    </StepperContainer>
  )
}

export default StepperHorizontal
