import React from 'react'
import { SectionWrapper } from './style'
import Faq from 'src/components/Faq/index'

type Faq = {
  faq: {question: string; answer: string}[];
}

const FAQComponent = (props: Faq) => {
  return (
    <SectionWrapper>
      <div className='container pt-5 pb-4 py-lg-5'>
        <div className='row'>
          <div className='mb-4'>
            <h2 className='fs-24 lh-30 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500'>Ficou com alguma dúvida?</h2>
          </div>
          <Faq answerData={props.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default FAQComponent
