import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      openBankingHeader: imageSharp(fluid: {originalName: { regex: "/open-banking-header/" }}) {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      openBankingVantagens: imageSharp(fluid: {originalName: { regex: "/open-banking-vantagens/" }}) {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      openBankingInterUIServices: imageSharp(fluid: {originalName: { regex: "/interui-services/" }}) {
        fluid(maxWidth: 25, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      news: imageSharp(fluid: {originalName: { regex: "/perto-das-novidades/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
