import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  min-height: 630px;
  background-color: ${orange.extra};
  display: flex;
  align-items: center;

  @media(max-width: ${breakpoints.md}) {
    min-height: 530px;
  }

  .social-media-card {
    min-height: 150px;
    padding: 20px;
    background-color: ${grayscale[100]};
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .p-200 {
    width: 200px;

    @media(max-width: ${breakpoints.lg}) {
      width: 100%;
    }
  }

  .p-250 {
    width: 250px;

    @media(max-width: ${breakpoints.lg}) {
      width: 100%;
    }
  }

`
