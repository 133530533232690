import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    min-height: 488px;
  }

  @media ${device.desktopXL} {
    min-height: 730px;
  }
`
