import React from 'react'
import { SectionWrapper } from './style'

const MaisAutonomia = () => {
  return (
    <SectionWrapper id='mais-autonomia'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-center justify-content-sm-end'>
          <div className='col-12 col-sm-6 col-lg-5 offset-lg-1 pr-0 pr-md-3'>
            <h2 className='fs-24 lh-30 fs-xl-48 lh-xl-60 text-grayscale--500 fw-600 mb-4'>
              Mais autonomia pra <span className='d-md-block d-xl-inline'>você e para a sua</span> empresa
            </h2>
            <p className='fs-18 lh-22 text-grayscale--400'>
              O Open Finance veio para facilitar o dia a dia do seu negócio. Você poderá centralizar as informações das contas bancárias da empresa num mesmo ambiente digital, ou seja, no mesmo app ou Internet Banking.
            </p>
            <p className='fs-18 lh-22 text-grayscale--400 mb-0'>
              Será possível resolver tudo de forma mais simples, optando sempre pelos produtos e serviços que melhor atenderem suas necessidades.
            </p>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default MaisAutonomia
